import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  isLoading: boolean = false;
  contactDetail = {};
  constructor(
  ) { }

  ngOnInit() {
    this.contactDetail = {
      "EMAIL_ID_TXT": "support@nmb.com.np",
      "TOLL_FREE_NO": "166-001-252-52",
      "PHONE_NO": "01-4246160"
    }
  }

}
