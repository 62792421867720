import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { AuthService } from './services/auth/auth.service';
import { CommonPageService } from './services/commonPage/common-page.service';

@Component({
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, right: -360 }),
            animate('0.6s ease-out',
              style({ opacity: 1, right: 0 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, right: 0 }),
            animate('0.6s ease-in',
              style({ opacity: 0, right: -360 }))
          ]
        )
      ]
    )
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isCommonPage = {
    open: false,
    data: '',
    title: ''
  };
  isUserlogin: boolean = false;
  isLoading: boolean = false;
  isLocateUsPage: boolean = false;

  constructor(
    private auth: AuthService,
    private commonPageSer: CommonPageService,
  ) { }

  onActivate(event) {
    window.scrollTo(0, 0)
    if (event['locateUsPage']) {
      this.isLocateUsPage = true;
    } else {
      this.isLocateUsPage = false;
    }
  }

  closeCommonPage() {
    const data = {
      open: false,
      title: '',
      type: ''
    }
    this.commonPageSer.data.next(data);
    document.querySelector('html').style.overflowY = 'auto'
  }


  ngOnInit() {
    this.auth.currentUser.subscribe(user => {
      if (user !== null && user['access_token']) {
        this.isUserlogin = true;
      } else if (user === null) {
        this.isUserlogin = false;
      }
    });
    this.commonPageSer.commonPageData.subscribe((data: any) => {
      if (data && data !== undefined && data !== null) {
        this.isCommonPage = data;
        document.querySelector('html').style.overflowY = 'hidden'
      }
    });
  }

}
