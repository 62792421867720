import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './routing-list';

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    useHash: true
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }