import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-step-change-btns',
  templateUrl: './step-change-btns.component.html',
  styleUrls: ['./step-change-btns.component.scss']
})
export class StepChangeBtnsComponent implements OnInit, OnChanges {

  @Input() isBackButton;
  @Input() isNextButton;
  @Input() isBackText: string;
  @Input() isNextText: string;
  @Output() stepButtonChange: EventEmitter<string> = new EventEmitter<string>();
  isBackBtn: boolean = true;
  isNextBtn: boolean = true;
  backText: string = 'Back';
  nextText: string = 'Next';

  constructor() { }

  ngOnChanges() {

    this.isBackBtn = this.isBackButton !== undefined ? this.isBackButton : true;
    this.isNextBtn = this.isNextButton !== undefined ? this.isNextButton : true;

    if (this.isBackText) {
      this.isBackText = this.isBackText;
    }
    if (this.isNextText) {
      this.nextText = this.isNextText;
    }
  }

  onBack() {
    this.stepButtonChange.emit('back');
  }
  onNext() {
    this.stepButtonChange.emit('next')
  }

  ngOnInit() {
  }

}
