import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { sideNav } from './side-nav';
import { SnackbarService } from '../../../services/snackBar/snackbar.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnChanges {

  @Input() menuToggle: boolean;
  @Output() sideMenuCose: EventEmitter<any> = new EventEmitter()
  @Output() resSideMenuCose: EventEmitter<any> = new EventEmitter()
  sideBarMenu: Array<any> = [];
  bankingType = '';

  constructor(
    private snackBar: SnackbarService,
    private router: Router,
    private auth: AuthService
  ) {
    this.sideBarMenu = sideNav;
    this.auth.currentUser.subscribe(data => {
      if (data && data !== null) {
        this.bankingType = data['bankingType']
      }
    })
  }

  onMenuClose() {
    this.sideMenuCose.emit(this.menuToggle);
    this.menuToggle = !this.menuToggle;
  }

  ngOnChanges() {
  }

  onUnderDev(work, route) {
    if (!work) {
      this.snackBar.openSnackBar('Under Development');
    } else {
      if (route) {
        this.router.navigate([route]);
        this.resSideMenuCose.emit(this.menuToggle)
      }
    }
  }


  ngOnInit() {

  }

}
