import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { HttpService } from '../../services/http/http.service';
import { apiPath } from '../../settings/apiPaths';
import { LanguageService } from '../../services/language/language.service';
import { SnackbarService } from '../../services/snackBar/snackbar.service';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsAndConditionComponent implements OnInit {

  termsCoditionDetail = {};
  isLoading: boolean = false;

  constructor(
    private http: HttpService,
    private dialogRef: MatDialogRef<TermsAndConditionComponent>,
    private langService: LanguageService,
    private snackBar: SnackbarService
  ) { }

  onAgreeTerms(event) {
    this.isLoading = true;
    this.http.POST(apiPath.ACCEPT_EULA, {}).subscribe(res => {
      console.log(res)
      if (res['status']) {
        this.dialogRef.close();
        this.isLoading = false;
      }
    }, err => {
      if (err) {
        this.isLoading = false;
        this.dialogRef.close();
      }
    });
  }

  getTermsCondition(lang) {
    this.isLoading = true;
    this.http.GET(`${apiPath.DEFAULT_REGISTRATION_AGREEMENT}?language${lang}`)
      .subscribe(res => {
        this.isLoading = false;
        if (res['status']) {
          this.termsCoditionDetail = res['payloadX']
          console.log(this.termsCoditionDetail);
        }
      }, err => {
        this.isLoading = false;
      })
  }

  ngOnInit() {
    let l = '';
    this.langService.currentLanguage.subscribe(lang => {
      l = lang
    });
    this.getTermsCondition(l);
  }

}
