import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { SnackbarService } from '../../../services/snackBar/snackbar.service';
import { CommonPageService } from '../../../services/commonPage/common-page.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() menuToggle: boolean;
  @Output() onMenuToggle: EventEmitter<boolean> = new EventEmitter();
  currentUser: object = {};
  bankingType = '';
  constructor(
    private auth: AuthService,
    private snackBar: SnackbarService,
    private commonPageSer: CommonPageService
  ) {
    this.auth.currentUser.subscribe(data => {
      if (data && data !== null) {
        this.bankingType = data['bankingType']
      }
    })
  }

  onMenuClose() {
    this.onMenuToggle.emit(true)
  }

  // Logout User
  onLogout() {
    this.snackBar.openSnackBar('Your successfully logout!');
    this.auth.onLogout();
  }

  uderDev() {
    this.snackBar.openSnackBar('Under Development')
  }

  onCommonPage(type, title) {
    const data = {
      open: true,
      data: type,
      title: title
    }
    this.commonPageSer.data.next(data)
  }

  ngOnInit() {
    this.auth.getAccessInitalData.subscribe(data => {
      if (data && data !== null) {
        this.currentUser = data['customerInitialData'];
      }
    })
  }

}
