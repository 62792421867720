import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionComponent } from '../terms-and-condition/terms-and-condition.component';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { SnackbarService } from 'src/app/services/snackBar/snackbar.service';
import { userAnkur } from './userAnkur';
import { userMayank } from './userMayank';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  menuToggle: boolean = true;
  isResponsive: boolean = false;
  isLoading = false;
  constructor(
    private termsAndConditionPopup: MatDialog,
    private auth: AuthService,
    private router: Router,
    private userIdle: UserIdleService,
    private snackBar: SnackbarService
  ) { }

  // @HostListener('window:resize', ['$event'])
  // onResize(event?) {
  //   //console.log(window.innerWidth, typeof (window.innerWidth));
  //   if (window.innerWidth <= 990) {
  //     this.menuToggle = false;
  //   } else {
  //     this.menuToggle = true;
  //   }
  // }

  onSideMenuCose(event) {
    this.menuToggle = !this.menuToggle;
  }

  onResSideMenuCose(event) {
    if (this.isResponsive) {
      this.menuToggle = !this.menuToggle;
    }
  }


  onMenuToggle(event) {
    this.menuToggle = !this.menuToggle;
  }

  getAccessData() {

    let userData = {}

    this.auth.currentUser.subscribe(data => {
      if (data && data !== null) {
        console.log(data, 'get name')
        if (data['username'] === 'P00484179') {
          userData = userMayank;
        } else {
          userData = userAnkur
        }
      }
    })

    this.isLoading = true;

    setTimeout(() => {
      this.isLoading = false;
      console.log(userData)
      this.auth.accessInitialData.next(userData);
      if (userData && !userData['customerInitialData']['eulaUpdated']) {
        this.termsAndConditionPopup.open(TermsAndConditionComponent, {
          width: '800px',
          disableClose: true,
          height: '80%'
        });
      }
    }, 1250);

  }

  getSystemIsIdeal() {
    //Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    // this.userIdle.onTimerStart().subscribe(count => console.log(count));

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.auth.onLogout();
      this.snackBar.openSnackBar('Your session timed out, please login again.')
    });
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }


  ngOnInit() {
    this.getSystemIsIdeal();
    if (sessionStorage.getItem(environment.Key)) {
      this.getAccessData();
    } else {
      this.router.navigate(['/login'])
    }
    if (window.innerWidth <= 990) {
      this.menuToggle = false;
      this.isResponsive = true;
    } else {
      this.menuToggle = true;
      this.isResponsive = false;
    }
  }

  ngOnDestroy() {
  }

}
