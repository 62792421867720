import { NgModule } from '@angular/core';
import { FaqComponent } from './faq.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { MatExpansionModule } from '@angular/material';

@NgModule({
  declarations: [
    FaqComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
    MatExpansionModule
  ],
  exports: [
    FaqComponent
  ]
})

export class FaqModule { }
