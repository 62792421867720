import { NgModule } from '@angular/core';
import { StepChangeBtnsComponent } from './step-change-btns.component';
import { SharedModule } from '../../../shared/shared.module';
import { TranslateModule } from '../../../pipes/translate/translate.module';

@NgModule({
  declarations: [StepChangeBtnsComponent],
  imports: [
    SharedModule,
    TranslateModule
  ],
  exports: [
    StepChangeBtnsComponent
  ]
})
export class StepChangeBtnsModule { }
