import { environment } from 'src/environments/environment';

export class apiPath {
    public static LOGIN = `${environment.LOGIN_API}auth/token`;
    public static ON_LOGOUT_WEB = '';
    public static DEFAULT_REGISTRATION_AGREEMENT = 'default/registration-agreement';
    public static ACCEPT_EULA = 'members/self/eula/accept';
    public static GET_CIF_NUMBER = 'smartpay/no-auth/cbs/members/send-cif';
    public static ACCESS_INITIAL_DATA = 'cbs/user/information';
    public static USER_INFO = 'cbs/user/information';
    public static CHECK_MEMBER = 'members/exist'
    public static FORGOT_PASSWORD = 'members/cbs/forgot-password';
    public static CREATE_PASSWORD = 'members/cbs/create-password';
    public static CHECK_DETAIL_2FA = 'members/cbs/validate/2fa';
    public static ACCOUNT_DETAIL = 'cbs/accounts';
    public static BENEFICIARY_LIST = 'cbs/members/beneficiary';
    public static BALANCE_ENQUIRY = 'payments/confirm/system-payment';
    public static FAQ_HELP = 'smartpay/no-auth/faqs';
    public static MAP_BANK_BRANCH_LOCATION = 'smartpay/no-auth/cbs/location';
    public static CONTACT_SUPPORT = 'smartpay/no-auth/bank/contact-support';
    public static GET_ACCOUNT_HISTORY = 'cbs/members/statement';
    public static GET_ALL_ACCOUNT_HISTORY = 'cbs/members/complete/statement';
    public static GET_EMAIL_STATEMENT = 'cbs/members/send/statement';
    public static GET_DOWNLOAD_STATEMENT = 'cbs/download/statement';
    public static TDS_CERTIFICATE = 'cbs/members/send/tds';
    public static GET_TRANSFER_BANK_LIST = 'nchl/ips/banks';
    public static GET_TRANSFER_BRANCH_LIST = 'nchl/ips/branches';

    public static FUNTD_TRANSFER = 'payments/schedule/system-payment';
    public static CONFIRM_FUNTD_TRANSFER = 'payments/confirm/schedule/system-payment';

    public static ACCOUNT_NUMBER_VALIDATE = 'cbs/validate/account-number'

    public static Add_PAYEE_DETAILS = 'cbs/members/beneficiary';
    public static DELETE_PAYEE = 'cbs/members/beneficiary';
    public static GET_FD_DETAIL = 'cbs/members/fd-account';
    public static GET_LOAN_DETAIL = 'cbs/members/loan-account';
    public static USER_REGISTER = 'smartpay/no-auth/sign-up/web';
    public static LANGUAGE_CHANGE = 'members/self/language';
    public static CHANGE_PASSWORD = 'members/self/password/with-otp';
    public static PROFILE_PICTURE_EDIT = 'members/self/photos';
    public static EMAIL_TRANFER_RECEIPT = 'cbs/mail/receipt';
    public static DOWNLOAD_TRANFER_RECEIPT = 'cbs/download/receipt';
    public static RATE_TRANSFER_EXPERINCE = 'cbs/rate/transaction';

    // OPEN FD
    public static GET_FD_PRODUCT = 'smartpay/no-auth/cbs/fd-list'
    public static OPEN_FD = 'payments/system-payment'
    public static OPEN_FD_CONFIRM = 'payments/confirm/system-payment'
    public static PREMATURE_TERMINATION_FD = 'payments/system-payment'
    public static PREMATURE_TERMINATION_FD_CONFIRM = 'payments/confirm/system-payment'
    public static GET_TERMINATION_CHARGE = 'cbs/members/fd-termination-charge'

    // PROFILE EDIT
    public static PROFILE_DETAIL = 'cbs/members/fetch-profile'
    public static PROFILE_EDIT = 'cbs/members/update-profile'
    public static IMAGE_UPLOAD = 'members/self/photos'

    public static SCHEDULED_PAYMENT = 'accounts/default/scheduled-payments';
    public static SCHEDULED_PAYMENT_ACCOUNT = 'members/default/advance/scheduled-payments';

    // SERVICE REQUEST
    public static SERVICE_REQUEST = 'payments/system-payment'
    public static SERVICE_REQUEST_CONFIRM = 'payments/confirm/system-payment'
    public static CHEQUE_CANCEL_REASON = 'smartpay/no-auth/reason/stop-check'
    public static OCCUPATION_LIST = 'smartpay/no-auth/occupations'
    public static MARITIAL_STATUS = 'smartpay/no-auth/marital-status'
    public static SOURCE_OF_FUND_LIST = 'smartpay/no-auth/source-of-fund'
    public static PURPOSE_ACCOUNT_LIST = 'smartpay/no-auth/purpose-of-account'
    public static ANTICIPATE_TRANSACTION_LIST = 'smartpay/no-auth/anticipate-transaction-list'
    public static ANTICIPATE_FREQUENCY_LIST = 'smartpay/no-auth/anticipation-freq-list'

    // MERCHANT PAY
    public static RECHARGE = 'payments/system-payment'
    public static RECHARGE_CONFIRM = 'payments/confirm/system-payment'
    public static GET_TOPUP_LIST = 'khalti/list/topup'
    public static VALIDATE_NUMBER = 'khalti/validate/services/topup'
    public static GET_INTERNET_LIST = 'khalti/list/internet'
    public static VALIDATE_INTERNET_USERNAME = 'khalti/search/packages/WORLD_LINK'
    public static GET_ADDRESS_DETAIL_LIST = 'smartpay/no-auth/cbs/provinces'

    public static CHECK_SYSTEM_PAYMENT = "payments/systemPayment";
    public static CHECK_MEMBER_PAYMENT = "payments/memberPayment";
    public static TT_BY_ID = "members/transaction/categories";
    public static CHECK_SELF_PAYMENT = "payments/selfPayment";
    public static CONFIRM_SELF_PAYMENT = "payments/confirmSelfPayment";
    public static CONFIRM_SYSTEM_PAYMENT = "payments/confirmSystemPayment";
    public static CONFIRM_MEMBER_PAYMENT = "payments/confirmMemberPayment";
} 