export const userAnkur = {
    "cifId": "R01048458",
    "role": "General Manager",
    "benificiaryName": "TILAK RAM ADHIKARI (HUSBAND)",
    "fatherName": "RAM PRASAD BHUSAL",
    "grandFatherName": "SASHIDHAR ADHIKARI",
    "spouseName": "TILAK RAM ADHIKARI",
    "son": "NULL",
    "son1": "NULL",
    "fatherInLaw": "NULL",
    "daughter": "NULL",
    "daughterInLaw": "NULL",
    "daughterInLaw1": "NULL",
    "daughter1": "NULL",
    "purposeOfAccount": "Investment",
    "foreignAssociate": "NULL",
    "kycReceivedStatus": "NULL",
    "antiAnnualTxnFreq": "Upto 100",
    "antiAnnualTxnMax": "Upto 5 Lac",
    "occupation": "Government employees",
    "maritialStatus": "Divorced",
    "incomeSource": "Gift",
    "nomineeName": "NO RECORD FOUND",
    "panNumber": "NO RECORD FOUND",
    "phoneOREmail5": null,
    "phoneEmailType5": null,
    "emailID5": null,
    "phoneNumber5": null,
    "phoneOREmail6": null,
    "phoneEmailType6": null,
    "emailID6": null,
    "phoneNumber6": null,
    "addressCategory7": null,
    "addressLine17": null,
    "addressLine27": null,
    "addressLine37": null,
    "cityCode": null,
    "stateCode": null,
    "countryCode": null,
    "customerInitialData": {
        "name": "ANKUR MISHRA",
        "nickName": "ANKUR",
        "cifNumber": "R01048458",
        "email": "No Data",
        "groupId": "5",
        "preferredLanguage": "ENGLISH",
        "dateOfActivation": null,
        "profileUrl": null,
        "lastLogin": "15-Feb-2020 06:57",
        "mothersMaidenName": "MOTHERLAND",
        "dateOfBirth": "19630203",
        "city": "MIG",
        "country": "MIG",
        "title": "MRS.",
        "phoneNumber": null,
        "mobileNumber": "9045594871",
        "govtId": null,
        "address1": "NO RECORD FOUND",
        "address2": "NO RECORD FOUND",
        "address3": "NO RECORD FOUND",
        "tempAddress1": null,
        "tempAddress2": null,
        "tempAddress3": null,
        "secondaryEmailAddresses": "ankur@paynet.pro",
        "images": [
            {
                "id": 70091,
                "caption": "Profile Image",
                "thumbnailUrl": "assets/images/ankur-profile.jpg",
                "fullUrl": "assets/images/ankur-profile.jpg",
                "lastModified": "2020-02-01T07:47:58.163+0000"
            }
        ],
        "eulaUpdated": true,
        "block": false,
        "kycPending": false
    },
    "branchName": "OM GALYANG",
    "branchId": "147",
    "mailingAddressCategory": "Mailing",
    "mailingAddressLine1": "MIGR",
    "mailingAddressLine2": "TULSIBHANJYANG-09,SYANGJA",
    "mailingAddressLine3": "PROVINCE NO - 4",
    "mailingCityCode": "MIG",
    "mailingStateCode": "MIG",
    "mailingCountryCode": "MIG",
    "pERMTAddressCategory": "NO RECORD FOUND",
    "pERMTAddressLine1": "NO RECORD FOUND",
    "pERMTAddressLine2": "NO RECORD FOUND",
    "pERMTAddressLine3": "NO RECORD FOUND",
    "pERMTCityCode": "NO RECORD FOUND",
    "pERMTStateCode": "NO RECORD FOUND",
    "pERMTCountryCode": "NO RECORD FOUND"
}