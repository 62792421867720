import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatProgressSpinnerModule } from '@angular/material';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [],
  imports: [],
  exports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatRippleModule
  ]
})
export class SharedModule { }
