import { Routes } from '@angular/router';
import { AuthGuard } from '../gaurds/authGuard/auth.guard';
import { HomeComponent } from '../components/home/home.component';

export const routes: Routes = [
    {
        path: '', component: HomeComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('../components/home/home.module').then(m => m.HomeModule)
            }
        ],
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('../components/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('../components/need-login-help/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
    },
    {
        path: 'locate-us',
        loadChildren: () => import('../components/locate-us/locate-us.module').then(m => m.LocateUsModule)
    },
    {
        path: 'user-register',
        loadChildren: () => import('../components/need-login-help/user-register/user-register.module').then(m => m.UserRegisterModule)
    },
    {
        path: 'onboarding',
        loadChildren: () => import('../components/on-boarding/on-boarding.module').then(m => m.OnBoardingModule),
    },
    {
        path: 'digital-onboarding',
        loadChildren: () => import('../components/digital-onboarding/on-boarding.module').then(m => m.OnBoardingModule),
    },

    {
        path: 'uae-cc-onboarding',
        loadChildren: () => import('../components/uae-cc-onboarding/on-boarding.module').then(m => m.OnBoardingModule),
    },

    {
        path: '**',
        loadChildren: () => import('../components/not-found/not-found.module').then(m => m.NotFoundModule)
    }
]

