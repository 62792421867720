export const sideNav = [
    {
        bankingType: ['retailBanking', 'corporateBanking', 'agentBanking'],
        title: 'Dashboard', route: '/', icon: 'assets/images/side-nav/dashboard.svg', work: true
    },
    { bankingType: ['agentBanking'], title: 'Open Account', route: '/agent/open-account', icon: 'assets/images/side-nav/utilty.svg', work: true },

    { bankingType: ['agentBanking'], title: 'Repayment Schedule', route: '/loan/repayment-schedule', icon: 'assets/images/side-nav/loan.svg', work: true },



    { bankingType: ['agentBanking'], title: 'Collect Loan', route: '/loan/collect-loan', icon: 'assets/images/side-nav/loan.svg', work: true },

    // { bankingType: ['retailBanking', 'corporateBanking', 'agentBanking'], title: 'Disburse Loan', route: '/loan/disburse-loan', icon: 'assets/images/side-nav/loan.svg', work: true },

    { bankingType: ['retailBanking', 'corporateBanking', 'agentBanking'], title: 'Loan', route: '/loan/disburse-loan', icon: 'assets/images/side-nav/loan.svg', work: true },

    {
        bankingType: ['nttBanking'],
        title: 'NTT Data',
        route: '',
        icon: 'assets/images/side-nav/fav-payment.svg',
        work: true,
        isSubmenu: true,
        submenu: [
            {
                title: 'Check Statements',
                route: '/ntt/check-statement'
            },
            {
                title: 'Payouts',
                isSubmenu2: true,
                submenuSec: [
                    { title: 'Single Payment', route: '/ntt/single-payment' },
                    { title: 'Bulk Payment', route: '/ntt/bulk-payment' },
                    { title: 'View Payment History', route: '/ntt/payment-history' }
                ]
            },
            {
                title: 'Collections',
                isSubmenu2: true,
                submenuSec: [
                    { title: 'Vendor Registration', route: '/ntt/vendor-registration' },
                    { title: 'Collections Report', route: '/ntt/collections' },
                ]
            }
        ]
    },

    { bankingType: ['agentBanking'], title: 'Cash Deposit', route: '/agent/cash-deposit', icon: 'assets/images/side-nav/fund-transfer.svg', work: true },

    { bankingType: ['agentBanking'], title: 'Cash Withdrawal', route: '/agent/cash-withdrawal', icon: 'assets/images/side-nav/utilty.svg', work: true },

    { bankingType: ['agentBanking'], title: 'Fund Transfer', route: '/agent/p2p', icon: 'assets/images/side-nav/school-fees.svg', work: true },
    { bankingType: ['agentBanking'], title: 'Remittance', route: '/agent/remittance', icon: 'assets/images/side-nav/loan.svg', work: true },

    { bankingType: ['retailBanking', 'agentBanking', 'corporateBanking'], title: 'Fixed Deposit', route: '/fixed-deposit', icon: 'assets/images/side-nav/fixed-deposit.svg', work: true },

    { bankingType: ['retailBanking', 'corporateBanking'], title: 'Accounts', route: '/account', icon: 'assets/images/side-nav/account.svg', work: true },

    { bankingType: ['retailBanking', 'corporateBanking'], title: 'Fund Transfer', route: '/fund-transfer', icon: 'assets/images/side-nav/fund-transfer.svg', work: true },
    //   { title: 'Favourite Payments', route: '/favourite-payments', icon: 'assets/images/side-nav/fav-payment.svg' },
    { bankingType: ['retailBanking', 'corporateBanking'], title: 'Cards', route: '/cards', icon: 'assets/images/side-nav/cards.svg', work: true },


    { bankingType: ['agentBanking'], title: 'Bill Payment', route: '/agent/bill-payment', icon: 'assets/images/side-nav/loan.svg', work: true },

    { bankingType: ['retailBanking', 'agentBanking'], title: 'Merchant Pay', route: '/merchant-pay', icon: 'assets/images/side-nav/bills-recharge.svg', work: true },

    { bankingType: ['retailBanking', 'agentBanking'], title: 'School Fee', route: '/merchant-pay/school-fee', icon: 'assets/images/side-nav/school-fees.svg', work: true },

    { bankingType: ['agentBanking'], title: 'Balance Check', route: '/agent/balance-check', icon: 'assets/images/side-nav/loan.svg', work: true },

    { bankingType: ['agentBanking'], title: 'Statement', route: '/agent/statement', icon: 'assets/images/side-nav/fav-payment.svg', work: true },

    {
        bankingType: ['corporateBanking'],
        title: 'Transfer & Bill Pay',
        route: '',
        icon: 'assets/images/side-nav/fav-payment.svg',
        work: true,
        isSubmenu: true,
        submenu: [
            {
                title: 'Bulk Payments',
                route: '/transfer-and-billpay/bulk-payment'
            },
            {
                title: 'Single Payments',
                route: '/transfer-and-billpay/single-payment'
            }
        ]
    },
    {
        bankingType: ['corporateBanking'],
        title: 'Payout',
        route: '',
        icon: 'assets/images/side-nav/fav-payment.svg',
        work: true,
        isSubmenu: true,
        submenu: [
            {
                title: 'Report',
                route: '/payout/report'
            },
            {
                title: 'Bulk Payments',
                route: '/payout/bulk-payment'
            },
            {
                title: 'Single Payments',
                route: '/payout/single-payment'
            }
        ]
    },

    {
        bankingType: ['corporateBanking'],
        title: 'Request Check',
        icon: 'assets/images/side-nav/school-fees.svg',
        work: true,
        isSubmenu: true,
        submenu: [
            {
                title: 'Status of Requests',
                isSubmenu2: true,
                submenuSec: [
                    { title: 'Completed Service Requests', route: '/request-check/complete-request' },
                    { title: 'Track Your Request', route: '/request-check/track-request' }
                ]
            },
            {
                title: 'Status of Approvals', isSubmenu2: true, submenuSec: [
                    { title: 'Pending On Me', route: '/request-check/pending-request' },
                    { title: 'Pending On Others', route: '/request-check/pending-request-other' },
                    { title: 'Rejected Transactions', route: '/request-check/rejected-trasaction' }
                ]
            }
        ]
    },
    {
        bankingType: ['corporateBanking'],
        title: 'TAX',
        icon: 'assets/images/side-nav/utilty.svg',
        work: true,
        isSubmenu: true,
        submenu: [
            {
                title: 'Tax Payments',
                isSubmenu2: true,
                submenuSec: [
                    { title: 'Pay VAT', route: '/tax/pay-vat' }
                ]
            },
            {
                title: 'VAT Related', isSubmenu2: true, submenuSec: [
                    { title: 'VAT Details', route: '/tax/vat-detail' },
                    { title: 'VAT Report', route: '/tax/vat-report' },
                    { title: 'VAT Accounts Mapping', route: '/tax/vat-account-mapping' },
                    { title: 'VAT Bulk Upload', route: '/tax/vat-bulk-upload' }
                ]
            }
        ]
    },
    {
        bankingType: ['corporateBanking'],
        title: 'CMS',
        icon: 'assets/images/side-nav/utilty.svg',
        work: true,
        isSubmenu: true,
        submenu: [
            {
                title: 'Collections & Payments',
                isSubmenu2: true,
                submenuSec: [
                    { title: 'CMS Collections (Web CMS)', route: '/cms/web-collection' },
                    { title: 'CMS Payments (IPS)', route: '/cms/ips-collection' }
                ]
            },
            {
                title: 'Activation', isSubmenu2: true, submenuSec: [
                    { title: 'Appy for Invoice Collection', route: '/cms/apply-for-invoice' },
                    { title: 'Request for a POS', route: '/cms/request-pos' }
                ]
            },
            {
                title: 'CMS Services', isSubmenu2: true, submenuSec: [
                    { title: 'CMS support request', route: '/cms/cms-support-request' }
                ]
            }
        ]
    },
    {
        bankingType: ['corporateBanking'],
        title: 'Collect funds',
        icon: 'assets/images/side-nav/utilty.svg',
        work: true,
        isSubmenu: true,

        submenu: [
            {
                title: 'Bulk Payments',
                route: '/collect-funds/bulk-payment'
            },
            {
                title: 'Collections & Payments',
                isSubmenu2: true,
                submenuSec: [
                    { title: 'CMS Collections (Web CMS)', route: '/collect-funds/web-collection' },
                    { title: 'CMS Payments (IPS)', route: '/collect-funds/ips-collection' }
                ]
            },
            {
                title: 'Activation', isSubmenu2: true, submenuSec: [
                    { title: 'Appy for Invoice Collection', route: '/collect-funds/apply-for-invoice' },
                    { title: 'Request for a POS', route: '/collect-funds/request-pos' }
                ]
            },
            {
                title: 'CMS Services', isSubmenu2: true, submenuSec: [
                    { title: 'CMS support request', route: '/collect-funds/cms-support-request' }
                ]
            }
        ]
    },
    {
        bankingType: ['corporateBanking'],
        title: 'Trade',
        icon: 'assets/images/side-nav/school-fees.svg',
        work: true,
        isSubmenu: true,
        submenu: [
            { title: 'OneView Dashbaord', route: '/trade' },
            {
                title: 'Trade Services',
                isSubmenu2: true,
                submenuSec: [
                    { title: 'Trade Online', route: '/trade/trade-online' },
                    { title: 'Export Invoice Discounting', route: '/trade/export-invoice-discounting' },
                    { title: 'Regulatory Pendency Report', route: '/trade/regulartory-pending-report' }
                ]
            },
            {
                title: 'Foreign Inward Remittance', isSubmenu2: true, submenuSec: [
                    { title: 'Settlement', route: '/trade/settlement' },
                    { title: 'EEFC to NPR conversion', route: '/trade/eefctoconversion' }
                ]
            },
            {
                title: 'Trade MIS', isSubmenu2: true, submenuSec: [
                    { title: 'Gurantees', route: '/trade/gurantees' },
                    { title: 'Letters of Credit', route: '/trade/lettersofcredit' },
                    { title: 'Bills', route: '/trade/bills' }
                ]
            }
        ]
    },
    { bankingType: ['retailBanking', 'corporateBanking'], title: 'Service Request', route: '/service-request', icon: 'assets/images/side-nav/service-request-icon.svg', work: true }
]
