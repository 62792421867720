import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface commonPage {
  open: boolean;
  data: string
}

@Injectable({
  providedIn: 'root'
})
export class CommonPageService {

  data = new Subject();
  commonPageData = this.data.asObservable()

  constructor() { }
}
