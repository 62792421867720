import { Injectable } from '@angular/core';
import { LanguageService } from '../../services/language/language.service';
import { HttpClient, HttpBackend } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private http: HttpClient;
  data: any = {};

  constructor(
    private handler: HttpBackend,
    private lang: LanguageService
  ) {
    this.http = new HttpClient(this.handler);
    this.lang.currentLanguage.subscribe(l => {
      this.use(l);
    });
  }

  use(lang: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang}.json`;
      this.http.get<{}>(langPath).subscribe(
        translation => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error => {
          this.data = {};
          resolve(this.data);
        }
      );
    });
  }

}
