import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { TermsAndConditionComponent } from './terms-and-condition.component';
import { MatDialogModule } from '@angular/material/dialog';
import { StepChangeBtnsModule } from '../common/step-change-btns/step-change-btns.module';

@NgModule({
  imports: [
    SharedModule,
    MatDialogModule,
    StepChangeBtnsModule
  ],
  declarations: [
    TermsAndConditionComponent
  ],
  entryComponents: [
    TermsAndConditionComponent
  ],
  exports: [
    TermsAndConditionComponent,
  ]
})
export class TermsAndConditionModule { } 
