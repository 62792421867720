import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LanguageService } from '../../../services/language/language.service';
import { AuthService } from '../../../services/auth/auth.service';
import { HttpService } from '../../../services/http/http.service';
import { apiPath } from '../../../settings/apiPaths';
import { SnackbarService } from '../../../services/snackBar/snackbar.service';

export interface Language {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-language-change',
  templateUrl: './language-change.component.html',
  styleUrls: ['./language-change.component.scss']
})
export class LanguageChangeComponent implements OnInit {

  @Output() onLanguageChange: EventEmitter<string> = new EventEmitter<string>()

  selectedLanguage = 'ENGLISH'; // Default Language
  languageCode: Language[] = [
    { value: 'ENGLISH', viewValue: 'EN' },
    { value: 'NEPALI', viewValue: 'NE' },
    { value: 'BANGLADESH', viewValue: 'BN' },
  ];

  constructor(
    private auth: AuthService,
    private language: LanguageService,
    private http: HttpService,
    private snackBar: SnackbarService
  ) {
    this.language.emit(this.selectedLanguage)
  }

  // Language Change
  onLanguageCode(event) {
    this.language.emit(event.value);
    this.onLanguageChange.emit(event.value);
    // this.snackBar.openSnackBar('Under Development')
    // if (this.auth.getAccessToken()) {
    //   this.setLanguage(event.value);
    // }
  }

  setLanguage(lang) {
    const data = {
      notificationLanguage: lang
    }
    this.http.POST(apiPath.LANGUAGE_CHANGE, data).subscribe(res => {
      if (res['status']) {
        this.language.emit(lang);
      } else {
        this.selectedLanguage = 'ENGLISH'
      }
    })
  }

  ngOnInit() {
    this.auth.getAccessInitalData.subscribe(data => {
      if (data && data !== null) {
        this.selectedLanguage = data['customerInitialData']['preferredLanguage']
        this.language.emit(this.selectedLanguage);
      }
    })
  }

}
