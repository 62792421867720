import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { HttpClientModule } from '@angular/common/http';
import { TranslationService } from './services/translation/translation.service';
import { DeviceDetectorModule } from 'ngx-device-detector';

// SharedModuel
import { SharedModule } from './shared/shared.module'

// Angular Material
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule, MAT_LABEL_GLOBAL_OPTIONS, MatIconModule } from '@angular/material';
import { MatMenuModule } from '@angular/material/menu';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

// COMPONENTS
import { HomeComponent } from './components/home/home.component';
import { SideMenuComponent } from './components/common/side-menu/side-menu.component';
import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { LoginHeaderComponent } from './components/common/login-header/login-header.component';
import { SnackBarComponent } from './services/snackBar/snack-bar.component';
import { TermsAndConditionModule } from './components/terms-and-condition/terms-and-condition.module';
import { FaqModule } from './components/faq/faq.module';
import { SupportModule } from './components/support/support.module';

import { UserIdleModule } from 'angular-user-idle';
import { LanguageChangeModule } from './components/common/language-change/language-change.module';
import { TranslateModule } from './pipes/translate/translate.module';
import { ConnectionCheckComponent } from './components/common/connection-check/connection-check.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';


export function setupTranslateFactory(service: TranslationService) {
  return () => service.use(environment.DefaultLanguage);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SideMenuComponent,
    HomeComponent,
    LoginHeaderComponent,
    SnackBarComponent,
    ConnectionCheckComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    LoadingBarModule,
    LoadingBarRouterModule,
    HttpClientModule,
    DeviceDetectorModule.forRoot(),
    MatSelectModule,
    MatSnackBarModule,
    MatMenuModule,
    MatIconModule,
    MatBottomSheetModule,
    TermsAndConditionModule,
    FaqModule,
    SupportModule,
    LanguageChangeModule,
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) 
    // and `ping` is 120 (2 minutes).
    UserIdleModule.forRoot({ idle: 180, timeout: 10, ping: 5 }),
    TranslateModule
  ],
  entryComponents: [
    SnackBarComponent
  ],
  providers: [
    { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'never' } },
    TranslationService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslationService],
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
