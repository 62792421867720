import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  @ViewChild('myaccordion', { static: true }) myPanels: MatAccordion;
  collapsedH: string = '100%';
  expandedH: string = '100%';
  isLoading: boolean = false;
  faqListArray: Array<any> = [];
  constructor(
  ) { }

  getFaq() {
    this.isLoading = true;
    setTimeout(() => {
      this.faqListArray = [
        {
          "id": 17,
          "helpQuestion": "What type of device and software do I need to use PayNet internet banking?",
          "helpAnswer": "PayNet bank internet banking works on any browser on all screen sizes. Thus you can access internet banking on your laptop, tab, personal computer and mobile handsets.",
          "helpChannel": "web",
          "persistent": true,
          "name": "17#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 18,
          "helpQuestion": "Will I be charged for the PayNet internet banking service?",
          "helpAnswer": "Yes, PayNet bank charges an annual fee for providing digital banking services comprising of mobile and internet. You can only use the services if you have subscribed for it. Kindly visit the support page to contact support or visit the nearest branch. ",
          "helpChannel": "web",
          "persistent": true,
          "name": "18#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 19,
          "helpQuestion": "What type of internet connection is required to use PayNet internet banking?",
          "helpAnswer": "You will require a 4G or a broadband WiFi for best experience.",
          "helpChannel": "web",
          "persistent": true,
          "name": "19#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 20,
          "helpQuestion": "Can I use PayNet internet banking without registering for mobile banking?",
          "helpAnswer": "Yes you can only choose to use internet banking without activating mobile banking.",
          "helpChannel": "web",
          "persistent": true,
          "name": "20#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 21,
          "helpQuestion": "I am already a internet banking user, How do I use the updated internet application?",
          "helpAnswer": "You do not need to do anything new, you will be guided through a simple activation process upon logging into the new internet application for the first time.",
          "helpChannel": "web",
          "persistent": true,
          "name": "21#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 22,
          "helpQuestion": "What are the steps to activate PayNet internet application?",
          "helpAnswer": "<html><div><ol type=\"a\"><li>Make sure you already have an account at PayNet Bank.</li><li>Ensure that you have subscribed for the internet banking service and paid the fee, you can check with your branch if not sure.</li><li>Ensure you have received an activation message for mobile and internet banking from the bank.</li><li>Make sure you have a laptop, tab or any screen to access a web browser like Chrome, Safari or Mozilla.</li><li>Visit the PayNet bank's website and click on internet banking option.</li><li>If you are using the new internet banking for the first time, the web application will guide you through the steps.</li></ol></div></html>",
          "helpChannel": "web",
          "persistent": true,
          "name": "22#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 23,
          "helpQuestion": "What is my daily transaction limit?",
          "helpAnswer": "Daily transaction limit is managed by the bank and cannot be changed by the customers as of now. The limit will be updated by the bank as per the central bank guidelines.",
          "helpChannel": "web",
          "persistent": true,
          "name": "23#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 24,
          "helpQuestion": "How can I change my daily transaction limit?",
          "helpAnswer": "You cannot change your transaction limit.",
          "helpChannel": "web",
          "persistent": true,
          "name": "24#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 25,
          "helpQuestion": "Can I use the internet banking on any device?",
          "helpAnswer": "Yes you can use internet banking on any device with all screen sizes, supporting internet browser. Thus you can access internet banking on your laptop, tab, personal computer and mobile handsets. ",
          "helpChannel": "web",
          "persistent": true,
          "name": "25#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 26,
          "helpQuestion": "Can I login into my internet account from multiple devices at once?",
          "helpAnswer": "No you cannot login to the internet banking application using multiple devices. A user can only login to one device at a time.",
          "helpChannel": "web",
          "persistent": true,
          "name": "26#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 27,
          "helpQuestion": "Where do I get my internet banking password?",
          "helpAnswer": "You will have to create your password during the first time internet application activation, you will guided through the process, please ensure you create a strong password. Please do not share your password with anyone.",
          "helpChannel": "web",
          "persistent": true,
          "name": "27#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 28,
          "helpQuestion": "I have forgotten my passwod, What do I do?",
          "helpAnswer": "You can use the password reset option on the login page to reset your password.",
          "helpChannel": "web",
          "persistent": true,
          "name": "28#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 29,
          "helpQuestion": "My internet banking Password is blocked, What do I do?",
          "helpAnswer": "You need to wait for 12 Hrs before your password is unblocked, or you can choose to reset your password, using the password reset function on the login page.",
          "helpChannel": "web",
          "persistent": true,
          "name": "29#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 30,
          "helpQuestion": "How can I change the language of internet banking?",
          "helpAnswer": "You will be asked to select your preferred language during the one time activation process once you use the new internet banking application for the first time. Subsequently you can choose to change the language by visiting settings section in the side menu.",
          "helpChannel": "web",
          "persistent": true,
          "name": "30#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 31,
          "helpQuestion": "Why it is important to update my computer and browser?",
          "helpAnswer": "PayNet bank advises users to regularly update their operating system and check the devices for viruses, malicious applications and content.",
          "helpChannel": "web",
          "persistent": true,
          "name": "31#HelpQuestionAnswer",
          "transient": false
        },
        {
          "id": 32,
          "helpQuestion": "Do & Don'ts.",
          "helpAnswer": "<html><div><ol type=\"a\"><li>Never share your password with any one. </li><li>Do not choose a simple password like repeat numbers, date of birth or marriage anniversary, like 1111, 1988. </li><li>Use password which cannot easily be guessed by any one or are available in public domain like social media or government documents.</li> <li>Ensure your computer's or phone screen is locked using a secret PIN so that the application cannot be accessed by unauthorised user. </li><li>Do not allow others to access your internet banking application. </li><li>Change your internet banking password regularly.</li></div></html>",
          "helpChannel": "web",
          "persistent": true,
          "name": "32#HelpQuestionAnswer",
          "transient": false
        }
      ]
      this.isLoading = false;
    }, 750);

  }

  ngOnInit() {
    this.getFaq();
  }

}
