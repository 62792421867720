import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  currentLanguage = new BehaviorSubject('ENGLISH');
  constructor() { }

  emit(languageCode) {
    this.currentLanguage.next(languageCode);
  }

}
