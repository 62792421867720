import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { SnackbarService } from '../snackBar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private snackbar: SnackbarService,
  ) { }

  statusTrue(res) {
    if (res['messages'] && Array.isArray(res['messages']) && res['messages'].length) {
      this.snackbar.openSnackBar(res['messages'][0].longMessage);
    }
  }

  catchErr(err) {
    if (err && err['sessionExpired']) {
      this.auth.onLogout();
      if (err['errors'] && Array.isArray(err['errors']) && err['errors'].length) {
        this.snackbar.openSnackBar(err['errors'][0].longMessage, 'errorClass');
      }
    } else if (err['errors'] && Array.isArray(err['errors']) && err['errors'].length) {
      this.snackbar.openSnackBar(err['errors'][0].longMessage, 'errorClass');
    }
  }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    if (this.auth.getAccessToken()) {
      headersConfig['Authorization'] = 'Basic ' + this.auth.getAccessToken();

    }
    return new HttpHeaders(headersConfig);
  };

  GET<ResultModel>(path: string, searchParams: HttpParams = new HttpParams()): Observable<ResultModel> {
    return this.http.get<ResultModel>(`${environment.API_PATH}${path}`, { headers: this.setHeaders(), params: searchParams }
    ).pipe(
      tap(
        event => {
          if (!event['status']) {
            this.catchErr(event)
          } else {
            this.statusTrue(event)
          }
        },
        err => {
          this.catchErr(err['error'])
        }
      )
    );
  }

  POST<ResultModel>(path: string, body: Object = {}): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${environment.API_PATH}${path}`, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(
        tap(
          event => {
            if (!event['status']) {
              this.catchErr(event)
            } else if (event['status']) {
              this.statusTrue(event)
            }
          },
          err => {
            this.catchErr(err['error'])
          }
        )
      );
  }

  PUT<ResultModel>(path: string, body: object = {}): Observable<ResultModel> {
    return this.http.put<ResultModel>(`${environment.API_PATH}${path}`, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(
        tap(
          event => {
            if (!event['status']) {
              this.catchErr(event)
            } else {
              this.statusTrue(event)
            }
          },
          err => {
            this.catchErr(err['error'])
          }
        )
      );
  }



  DELETE<ResultModel>(path: string): Observable<ResultModel> {
    return this.http.delete<ResultModel>(`${environment.API_PATH}${path}`,
      { headers: this.setHeaders() })
      .pipe(
        tap(
          event => {
            if (!event['status']) {
              this.catchErr(event)
            } else {
              this.statusTrue(event)
            }
          },
          err => {
            this.catchErr(err['error'])
          }
        )
      )
  }


}

