import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { loginHeaderNav } from './login-head-nav';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CommonPageService } from '../../../services/commonPage/common-page.service';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss']
})
export class LoginHeaderComponent implements OnInit {

  loginNav: Array<any> = loginHeaderNav;
  @ViewChild('responsiveMenu', { static: true }) responsiveMenu: TemplateRef<any>;
  isResponsive: boolean = false;

  constructor(
    private bottomSheet: MatBottomSheet,
    private commonPageSer: CommonPageService
  ) { }

  openResponsiveMenu(): void {
    this.bottomSheet.open(this.responsiveMenu);
  }
  closeReponsiveMenu(): void {
    this.bottomSheet.dismiss()
  }

  onPageOpen(nav) {
    if (nav.type) {
      const data = {
        open: true,
        data: nav.type,
        title: nav.title
      }
      this.commonPageSer.data.next(data)
    }
  }
  ngOnInit() {
  }

}
