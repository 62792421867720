import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUser = new BehaviorSubject(null);
  private readonly key = environment.Key;
  accessInitialData = new BehaviorSubject(null);
  getAccessInitalData = this.accessInitialData.asObservable()

  constructor(
    private router: Router,
    private dialog: MatDialog
  ) {
    if (sessionStorage.getItem(this.key)) {
      const data = JSON.parse(sessionStorage.getItem(this.key));
      this.currentUser.next(data);
    }
  }

  // When User login data is save in sessionStorage
  onUserLogin(data) {
    if (data && data !== undefined) {
      this.currentUser.next(data);
      sessionStorage.setItem(this.key, JSON.stringify(data));
    }
  }

  // Remove user from session storage to log user out
  onLogout() {
    this.currentUser.next(null);
    sessionStorage.removeItem(this.key);
    this.accessInitialData.next(null);
    this.router.navigate(['/login']);
    this.dialog.closeAll()
  }

  getAccessToken() {
    let token = '';
    this.currentUser.subscribe(user => {
      if (user && user !== null && user['access_token']) {
        token = user['access_token'];
      }
    })
    return token;
  }

}
